<template>
  <div v-if="initialized">
    <TableHeaderPanel :loading="loading"
                      :addNewButton="addNewButton"
                      :filterButton="filterButton"
                      @onChangePeriod="doRequest"
                      @reset="resetPreferences"
                      @update-limit="updateLimit"
                      @search="searchPage"
                      ref="headerPanel"/>
    <div class="position-relative table-responsive">
      <table class="table border">
        <TableHeader :columnConfig="columnConfig"
                     :sortField="sortField"
                     :order="order"
                     @sort-update="updateSort"
                     @select-page="selectPage"
                     ref="header"/>
        <TableBody v-on="$listeners"
                   :list="list"
                   :columnConfig="columnConfig"
                   ref="body"
                   v-if="hasContent"/>
      </table>
    </div>
    <TableFooter class="bottom-controls"
                 :loading="loading"
                 :totalCount="totalCount"
                 :start="page"
                 :limit="limit"
                 @change-pagination="changePagination"/>

    <FilterModal ref="filterModal"
                 :filterConfig="filterConfig"
                 @okButton="saveModal"
                 @cancelButton="cancelModal"/>
    <b-modal ref="filter-modal" v-model="showModal" title="Filter modal" hide-footer>
      <form @submit.stop.prevent="filterSubmit">
        <b-form-group>
          <label class="text-black-50">User Type</label>
          <b-form-select v-model="selectedType" :options="types"></b-form-select>
        </b-form-group>
      </form>
      <footer class="modal-footer">
        <b-button class="btn btn-primary" @click="saveModal">OK</b-button>
        <b-button class="btn btn-secondary" @click="cancelModal">Cancel</b-button>
      </footer>
    </b-modal>
  </div>
</template>

<script>
  import TableHeaderPanel from './TableHeaderPanel.vue';
  import TableHeader from './TableHeader.vue';
  import TableBody from './TableBody.vue';
  import TableFooter from './TableFooter.vue';
  import FilterModal from './FilterModal';

  export default {
  name: 'ListingComponent',
  components: {
    TableHeaderPanel,
    TableHeader,
    TableBody,
    TableFooter,
    FilterModal
  },
  props: {
    showFilterButton:{
      type: Boolean,
    },
    requestProvider: {
      type: Function,
      required: true
    },
    columnConfig: {
      type: Array,
      default: () => [],
      required: true
    },
    addNewButton: {
      type: Function
    },
    filterConfig: {
      type: Object,
      default: () => {}
    },
  },
  data () {
    return {
      loading: false,
      initialized: false,
      list: [],
      totalCount: 0,
      searchKey: '',
      clientId: null,
      marketplace: null,
      page: 1,
      limit: 20,
      sortField: '',
      order: 0,
      entityId: null,
      showModal: false,
      selectedType: null,
      types: [{value: "", text: 'Please select'}, {value: 'Active', text: 'Active'}, {value: 'Inactive', text: 'Inactive'}]
    }
  },
    computed: {
    hasContent () {
      return this.totalCount
    }
  },
  created () {
    this.onInitialize()
  },
  methods: {
    onInitialize () {
      this.$nextTick(() => {
        this.initialized = true
      })
      this.doRequest()
    },
    resetPreferences () {
      this.page = 1
      this.searchKey = ''
      this.clientId = null
      this.selectedType = null,
      this.onInitialize()
    },
    doRequest () {
      this.loading = true

      this.requestProvider(this.getRequestParams())
        .then(({data}) => {
          if (data && data.data) {
            this.loading = false

            this.list = data.data.items
            this.totalCount = data.data.total || 0
          }
        }).catch(() => {
          this.loading = false
        })
    },
      getSortOrder() {
          return this.order === 0 ? 'asc' : 'desc'
      },
      getRequestParams() {
        if (this.$route.params.id && this.$route.params.id > 0) {
          this.entityId = this.$route.params.id;
        }
          const params = {
            start: (this.page - 1) * this.limit,
            limit: this.limit,
            searchKey: this.searchKey,
            sortField: this.sortField,
            clientId: this.clientId,
            sortOrder: this.getSortOrder(),
            entityId: this.entityId,
            type: this.selectedType
        }
      return {params}
    },
    updateSort (sortField) {
      if (this.sortField === sortField) {
        this.order = this.order === 0 ? 1 : 0
      } else if (sortField === '') {
        this.order = 0
      }
      this.sortField = sortField
      this.doRequest()
    },
    searchPage () {
      this.page = 1

      this.doRequest()
    },
    filterButton () {
      if (this.showFilterButton) {
        return <b-button variant="secondary" class="mr-3" onClick={this.onClickFilterButton}>Filter</b-button>
      } else {
        return false
      }
    },
    onClickFilterButton () {
      this.showModal = true
    },
    updateLimit (limit) {
      this.limit = limit

      this.doRequest()
    },
    selectPage (state) {
      this.$refs.body.selectPage(state)
    },
    changePagination (page) {
      this.page = page

      this.doRequest()
    },
    saveModal () {
      this.$refs['filter-modal'].hide()
      this.page = 1
      this.doRequest()
    },
    cancelModal () {
      this.$refs['filter-modal'].hide()
    },
  }
}
</script>

<style scoped>

</style>
